require("./index.scss");
require("./../common/video.scss");
//require("./../common/t-video_small.scss");
require("./../common/page-info.scss");

import event from "../../modules/szfront_utils/event";
import StringCutter from "../../modules/szfront_utils/StringCutter";
import debounce from "../../modules/szfront_utils/debounce";
import spoiler from "../../modules/szfront_utils/spoiler";

event.on("page_main_index:init", function() {
  if (StringCutter.isCssCut()) {
    let titles = ".video__title";
    let options = {
      lines: 2,
      ellipsis: "...",
      breakpoints: {
        "(max-width: 767px)": 3
      }
    };

    let titleCutter = new StringCutter(titles, options);

    StringCutter.fontsLoaded(() => titleCutter.update());
    window.addEventListener("resize", debounce(titleCutter.update.bind(titleCutter), 500));
  }

  let spoilerBtn = document.querySelector(".page-info__read-more");
  if (spoilerBtn) {
    spoiler(spoilerBtn);
  }
});
