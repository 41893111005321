import Scroller from "../../modules/szfront_core/Scroller";
import event from "../../modules/szfront_utils/event";
require("./index.scss");

/**
 * Init slider only on homepage
 */
event.on("page_main_index:init", function() {
  let sliderEl = document.getElementById('m-slider');

  if (sliderEl) {
    new Scroller(sliderEl, {
      container: '.m-slider__cnt',
      scrolled: '.m-slider__container',
      item: '.m-slider__item',
      dots: '.m-slider__dots',
      dotSelectedCN: 'm-slider__dots--selected',
      dotStepCN: 'm-slider__dots--step-',
      prevButton: '.m-slider__prev-btn',
      nextButton: '.m-slider__next-btn',
    });
  }
});
